import React from 'react';
import { Link } from 'gatsby'
import Article from '../../components/Article'
import "../../components/App.css"
import "../../components/ArticleContent.css"

export default function BlogHairloss() {
	
	return (
		<Article
			title='My experience of curing my hair loss'
			takeaway_title='Immediate actions required to stop hairloss'
			page_id='Blog - Hairloss'
			read_time='5 mins'
			author='Brij Bhushan'
			image1='/hair-before-after-2021.jpg'
			image1_description='With these few changes, the density of hair improved in a year'
			image2='/hair-before-after-2020.jpg'
			image2_description="Deterioration while undergoing treatment from the country's leading trichology centre"
			toggle_article='Solution'
			toggle_url='/article/soln_hairloss'
			related_article='Solution - Hairloss'
			takeaway_data={TakeawayData}
			related_articles={RelatedArticles}
		>
			<div className='article_container'>
					<p className='article_para'>
						My father lost his hair in his 30s itself. One after another, all my brothers had a severe hair loss. From an early age, I was told that sooner or later you too are likely to lose your hair. Once I turned 40 yrs old, it was evident that my hair is not as thick as it was a few years ago. Like everyone, I used to wonder if it is in my heredity then what can I do about it. I used to just hope that the longer they last the better it is.
					</p>
					<p className='article_para'>
						With the turn of events, I changed my job and the new job was a bit demanding. Stress at work increased many folds and to manage the workload, I started cutting down on my sleep. No wonder that I started experiencing a heavy hair loss. Daily after shower I used to notice a lump of hairs stuck on the drain. That’s when I suddenly got a call from one of the leading trichology centres in the country and they offered me a free consultation. I gladly took the appointment for the coming weekend, thinking perhaps they will do some magic to save me from what looked inevitable.
					</p>
					<p className='article_para'>
						Then the expensive treatment started at the trichology centre. First, I was prescribed a daily tablet and soon I was enrolled with their year long treatment where my scalp was treated with a roller with pointed spikes, a proprietary hair serum and proprietary hair shampoo. Every month their staff tried to convince me that my hairs are growing thicker, though my wife could hardly tell any difference. Overall, the results were mixed. The hair loss did reduce to some extent but my hair became very dry & unmanageable.
					</p>
					<p className='article_para'>
						It has been 1 year & 3 months into the treatment. The trichology centre was trying its best to convince me that the results have been extraordinary & now I should enroll for a maintenance package for another year. That was the time when I started researching medical problems in journals, ayurveda texts. Very soon I found out that the hair shampoo & the hair serum that I have been prescribed consists of harsh chemicals and the trichology centre is on one hand managing my hair loss through tablets but on the other hand damaging my hairs with its other products. So, I questioned their doctor that isn’t their shampoo causing drying in my hair & making them unmanageable. He responded that my hairs are present only because of their products & without them I may not even have those unmanageable hairs. That’s when, I decided to try out an alternate regime for my hair based on my study of medical journals and ayurveda texts.
					</p>
					<p className='article_para'>
						That’s when I replaced my shampoo, conditioner, hair oil with healthy alternatives (will write a separate blog hair care products), and adopted the following two things in my daily routine:
						<ol className='article_list'>
							<li>Eating 4 curry leaves every morning on an empty stomach</li>
    						<li>Eating few sprouts</li>
    						<li>Take 1/3 tsp of Amalaki Rasayan with 1/2 tsp of honey, 2 hrs after the dinner</li>
    					</ol>
					</p>
					<p className='article_para'>
						For details of the solution and rationale behind how it works, one can refer to my other article on the hair loss solution at <Link to='/article/soln_hairloss'>here</Link>)
					</p>
					<p className='article_para'>
						With these changes along with other changes in my diet & lifestyle, soon my hair loss was almost zero. It has been 1.5 years since then and I am 45 years old (as on date). My hair loss continues to be almost zero & my hairs are far more manageable than what they have been earlier.
					</p>
			</div>
		</Article>
	);

}

const TakeawayData = [
	{
		takeaway: 'Every morning chew 4 curry leaves',
	},
	{
		takeaway: 'Add sprouts, pulses & soaked almonds to the diet',
	},
	{
		takeaway: 'Take 1/3rd tsp of Amalaki Rasayan, 2 hrs after dinner',
	},
];

const RelatedArticles = [
	{
		title: 'Solution - Hairloss',
		path: '/article/soln_hairloss',
	},
];



